export default {
  fonts: {
    body: 'system-ui, sans-serif',
    heading: '"Avenir Next", sans-serif',
    monospace: 'Menlo, monospace',
  },
  colors: {
    text: '#000',
    background: '#fff',
    primary: '#07c',
    secondary: 'red',
    modes: {
      dark: {
        text: '#fff',
        background: '#000',
        primary: '#0cf',
      }
    }
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
  lineHeights: {
    normal: 1.5,
    medium: 1.4,
  },
  fontSizes: {
    xs: 12,
    sm: 16,
    md: 32,
    lg: 48,
    xl: 64,
  },
  letterSpacings: {
    normal: "0",
    wide: "0.25em",
  },
  buttons: {
    primary: {
      color: "white",
      bg: "primary",
      "&:hover": {
        color: "primary",
        bg: "white",
      },
    },
    secondary: {
      color: "text",
      bg: "secondary",
      "&:hover": {
        color: "secondary",
        bg: "text",
      },
    },
    floatRight: {
      float: "right"
    }
  },
  text: {
    inPageLink: {
      color: 'black',
      fontWeight: 'bold',
      textDecoration: 'none',
    },
  },
}
